import React, {useState} from "react";
import { useFirebase, useFirestore } from 'react-redux-firebase'
import {handleSignInWithGoogle} from "../../features/auth/authSlice";
import { Redirect } from 'react-router-dom'

import {Form, Input, Button} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';

import styles from './Login.module.css'
import {timestamp} from "../../app/firebase";

const SignUpForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [redirectToHome, setRedirectToHome] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const auth = useFirebase().auth()
    const firestore = useFirestore()
    const handleSignUp = async (email, password, firstName, lastName) => {
        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password)
            firestore
                .collection('users')
                .doc(user?.uid as string)
                .set({
                    firstName,
                    lastName,
                    initials: firstName[0] + lastName[0],
                    createdAt: timestamp(),
                })
            setRedirectToHome(true);
        } catch ({ code, message }) {
            console.log(`code: ${code} message: ${message}`)
            setErrorMessage(message)
        } finally {
        }
    }
    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        if (name === "username") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        } else if (name === "firstName") {
            setFirstName(value);
        } else if (name === "lastName") {
            setLastName(value);
        }
    };
    if (redirectToHome) {
        return (
            <Redirect to="/" />
        );
    }
    return (
        <div className={styles.outerLoginContainer}>
            <div className={styles.middleLoginContainer}>
                <div className={styles.innerLoginContainer}>
                    <Form
                        name="normal_login"
                        className={styles.normalLoginForm}
                        initialValues={{ remember: true }}
                        onFinish = {() => {handleSignUp(email, password, firstName, lastName)}}
                    >
                        <Form.Item>
                            <h2>Sign up</h2>
                        </Form.Item>
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Username required'}]}
                        >
                            <Input
                                name="username"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email Address"
                                onChange = {(event) => onChangeHandler(event)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Password required' }]}
                        >
                            <Input
                                name="password"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                onChange = {(event) => onChangeHandler(event)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: 'First name required' }]}
                        >
                            <Input
                                name="firstName"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="First Name"
                                onChange = {(event) => onChangeHandler(event)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: 'Last name required' }]}
                        >
                            <Input
                                name="lastName"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Last Name"
                                onChange = {(event) => onChangeHandler(event)}
                            />
                        </Form.Item>
                        {errorMessage} <br/>
                        <br/>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                Sign up for Ramen App
                            </Button>
                            <br/>
                            <hr className={styles.hrText} data-content="Or"/>
                            <Button type="dashed" htmlType="button" className="login-with-google-button" onClick={handleSignInWithGoogle}>
                                Sign in with Google
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <a href="/signin">
                                Sign in with email and password
                            </a>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default SignUpForm;