import React from "react";
import {useSelector} from 'react-redux'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import {selectAuth} from '../../features/auth/authSlice'

import { Button } from 'antd';

const SignOut = () => {
    const auth = useFirebase().auth()
    const authState = useSelector(selectAuth)
    const onClick = () => {
        auth.signOut()
        console.log("Signed out")
    }

    return (
        <>
            {!isEmpty(authState) && (
                <Button type="dashed" htmlType="button" className="sign-out-button" onClick={onClick}>
                    Sign Out
                </Button>
            )}
        </>
    );
};

export default SignOut;