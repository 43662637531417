import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import 'antd/dist/antd.less'
import styles from './App.module.css'
import {Layout} from 'antd';

import {ApplicationsListPage} from "../features/applicationsList/ApplicationsListPage";
import {CyclesListPage} from "../features/cyclesList/CyclesListPage";

import LoginApp from '../login';
import RegistrationApp from '../register';
import HomePage from '../homepage';
import Dashboard from '../dashboard';
import ForgotPassword from '../login/components/ForgotPassword'
import SignUp from '../login/components/SignUp'
import Game2 from '../game2'
import NavBar from '../components/NavBar';
import {PrivateRoute} from '../features/auth/privateRoute'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <div className={styles.app}>
                <Layout className={styles.layout}>
                    <Switch>
                        <Route path="/signin" component={LoginApp}/>
                        <Route path="/login" component={LoginApp}/>
                        <Route path="/signup" component={SignUp}/>
                        <Route path="/forgot" component={ForgotPassword}/>
                        <PrivateRoute exact path="/">
                            <Dashboard/>
                        </PrivateRoute>
                        <Route path="/game2" component={Game2}/>

                        <Route path="/dev/applications" component={ApplicationsListPage}/>
                        <Route path="/dev/cycles" component={CyclesListPage}/>
                        <Route path="/dev/dashboard" component={Dashboard}/>
                        <Route path="/dev/homepage" component={HomePage}/>
                        <Route path="/dev/register" component={RegistrationApp}/>
                    </Switch>
                </Layout>
            </div>
        </BrowserRouter>
    );
}

export default App;
