import axios from 'axios'

const BASE_URL = '127.0.0.1:8000'

export interface Community {
    id: number
    name: string
}

export interface Cycle {
    id: number
    name: string
    community: number
}

export interface Application {
    id: number
    cycle: number
    email: string
    application_data: string
}

export interface CommunitiesResult {
    communities: Community[]
}

export interface CyclesResult {
    cycles: Cycle[]
}

export interface ApplicationsResult {
    applications: Application[]
}

export async function getCommunities(): Promise<CommunitiesResult> {
    const url = `http://${BASE_URL}/api2/closeknit/community/`

    try {
        const communitiesResponse = await axios.get<Community[]>(url)
        return {communities: communitiesResponse.data}
    } catch (err) {
        throw err
    }
}

export async function getCycles(): Promise<CyclesResult> {
    const url = `http://${BASE_URL}/api2/commonapp/cycles/`

    try {
        const cyclesResponse = await axios.get<Cycle[]>(url)
        return {cycles: cyclesResponse.data}
    } catch (err) {
        throw err
    }
}

export async function getApplications(): Promise<ApplicationsResult> {
    const url = `http://${BASE_URL}/api2/commonapp/applications/`

    try {
        const applicationsResponse = await axios.get<Application[]>(url)
        return {applications: applicationsResponse.data}
    } catch (err) {
        throw err
    }
}

export async function getApplication(id: number): Promise<Application> {
    const url = `http://${BASE_URL}/api2/commonapp/applications/{id}`

    const { data } = await axios.get<Application>(url)
    return data
}

export async function getCycle(id: number): Promise<Cycle> {
    const url = `http://${BASE_URL}/api2/commonapp/cycles/{id}`

    const { data } = await axios.get<Cycle>(url)
    return data
}
