import React from 'react'

import { Cycle } from '../../api/commonappAPI'
import { Button, Card } from "antd";

import styles from './CycleListItem.module.css'

type CycleListItemProps = Cycle

export const CycleListItem = ({id, name, community}: CycleListItemProps) => {
    return (
        <Card className={styles.cycle} title={name}>
            <p>Id:{id}</p>
            <p>Community: {community}</p>
            <p>To Review: 3</p>
            <p>Total Applications: 11</p>
            <Button type="primary">Edit</Button>
        </Card>
    )
}
