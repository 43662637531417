import React from "react";

import { LoginForm } from "./components";

const LoginApp: React.FC = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

export default LoginApp;
