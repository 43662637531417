import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {CyclesList} from "./CyclesList"

import {fetchCycles} from "./CyclesSlice";
import {RootState} from "../../app/rootReducer";

export const CyclesListPage = () => {
    const dispatch = useDispatch()
    const {
        isLoading,
        error: cyclesError,
        cycles
    } = useSelector((state: RootState) => state.cycles)

    useEffect(() => {
        dispatch(fetchCycles())
    }, [dispatch])

    if (cyclesError) {
        return (
            <>
                <h1>Something went wrong...</h1>
                <>{cyclesError.toString()}</>
            </>
        )
    }

    let renderedList = isLoading ? (
        <h3>Loading...</h3>
    ) : (
        <CyclesList cycles={cycles} />
    )

    return (
        <>
            {renderedList}
        </>
    )
}
