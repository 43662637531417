import React from 'react'

import { Application } from '../../api/commonappAPI'
import {Button, Card} from "antd";

import styles from "./ApplicationListItem.module.css";

type ApplicationListItemProps = Application

export const ApplicationListItem = ({id, cycle, email, application_data}: ApplicationListItemProps) => {
    return (
        <Card className={styles.application} title={email}>
            <p>Id:{id}</p>
            <p>Cycle: {cycle}</p>
            <p>ApplicationData: {application_data}</p>
            <Button type="default">Reject</Button>
            <Button type="primary">Pass</Button>
        </Card>
    )
}
