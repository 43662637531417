import React from 'react'

import { Application } from '../../api/commonappAPI'
import { ApplicationListItem } from './ApplicationListItem'

import styles from "./ApplicationsList.module.css";

interface Props {
    applications: Application[]
}

export const ApplicationsList = ({ applications }: Props) => {
    const renderedApplications = applications.map(application => (
        <li key={application.id}>
            <ApplicationListItem {...application} />
        </li>
    ))

    return <ul className={styles.applicationsList}>{renderedApplications}</ul>
}
