import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Application, ApplicationsResult, getApplication, getApplications } from '../../api/commonappAPI'
import { AppThunk } from '../../app/store'

interface ApplicationsState {
    applicationsById: Record<number, Application>
    applications: Application[],
    isLoading: boolean
    error: string | null
}

const applicationsInitialState: ApplicationsState = {
    applicationsById: {},
    applications: [],
    isLoading: false,
    error: null
}

function startLoading(state: ApplicationsState) {
    state.isLoading = true
}

function loadingFailed(state: ApplicationsState, action: PayloadAction<string>) {
    state.isLoading = false
    state.error = action.payload
}

const applications = createSlice({
    name: 'applications',
    initialState: applicationsInitialState,
    reducers: {
        getApplicationStart: startLoading,
        getApplicationsStart: startLoading,
        getApplicationSuccess(state, { payload }: PayloadAction<Application>) {
            const { id } = payload
            state.applicationsById[id] = payload
            state.isLoading = false
            state.error = null
        },
        getApplicationsSuccess(state, { payload }: PayloadAction<ApplicationsResult>) {
            const { applications } = payload
            state.isLoading = false
            state.error = null

            applications.forEach(application => {
                state.applicationsById[application.id] = application
            })

            state.applications = applications
        },
        getApplicationFailure: loadingFailed,
        getApplicationsFailure: loadingFailed
    }
})

export const {
    getApplicationsStart,
    getApplicationsSuccess,
    getApplicationStart,
    getApplicationSuccess,
    getApplicationFailure,
    getApplicationsFailure
} = applications.actions

export default applications.reducer

export const fetchApplications = (): AppThunk => async dispatch => {
    try {
        dispatch(getApplicationsStart())
        const applications = await getApplications()
        dispatch(getApplicationsSuccess(applications))
    } catch (err) {
        dispatch(getApplicationsFailure(err.toString()))
    }
}

export const fetchApplication = (id: number): AppThunk => async dispatch => {
    try {
        dispatch(getApplicationStart())
        const application = await getApplication(id)
        dispatch(getApplicationSuccess(application))
    } catch (err) {
        dispatch(getApplicationFailure(err.toString()))
    }
}