import * as firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyCS07khEYBbHf64AOkU8fp_BMG2eoe2i8g",
  authDomain: "common-app-9b3de.firebaseapp.com",
  databaseURL: "https://common-app-9b3de.firebaseio.com",
  projectId: "common-app-9b3de",
  storageBucket: "common-app-9b3de.appspot.com",
  messagingSenderId: "704801701935",
  appId: "1:704801701935:web:6c4360c8acc74de26f86da",
  measurementId: "G-0NFBQMJDV5"
};
firebase.initializeApp(config);
firebase.firestore()

/**
 * Get firestore timestamp
 * @type {() => firebase.firestore.FieldValue}
 */
export const timestamp = firebase.firestore.FieldValue.serverTimestamp

export default firebase;