import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Cycle, CyclesResult, getCycles, getCycle } from '../../api/commonappAPI'
import { AppThunk } from '../../app/store'

interface CyclesState {
    cyclesById: Record<number, Cycle>
    cycles: Cycle[]
    isLoading: boolean
    error: string | null
}

const cyclesInitialState: CyclesState = {
    cyclesById: {},
    cycles: [],
    isLoading: false,
    error: null
}

function startLoading(state: CyclesState) {
    state.isLoading = true
}

function loadingFailed(state: CyclesState, action: PayloadAction<string>) {
    state.isLoading = false
    state.error = action.payload
}

const cycles = createSlice({
    name: 'cycles',
    initialState: cyclesInitialState,
    reducers: {
        getCycleStart: startLoading,
        getCyclesStart: startLoading,
        getCycleSuccess(state, { payload }: PayloadAction<Cycle>) {
            const { id } = payload
            state.cyclesById[id] = payload
            state.isLoading = false
            state.error = null
        },
        getCyclesSuccess(state, { payload }: PayloadAction<CyclesResult>) {
            const { cycles } = payload
            state.isLoading = false
            state.error = null

            cycles.forEach(cycle => {
                state.cyclesById[cycle.id] = cycle
            })

            state.cycles = cycles
        },
        getCycleFailure: loadingFailed,
        getCyclesFailure: loadingFailed
    }
})

export const {
    getCyclesStart,
    getCyclesSuccess,
    getCycleStart,
    getCycleSuccess,
    getCycleFailure,
    getCyclesFailure
} = cycles.actions

export default cycles.reducer

export const fetchCycles = (): AppThunk => async dispatch => {
    try {
        dispatch(getCyclesStart())
        const cycles = await getCycles()
        dispatch(getCyclesSuccess(cycles))
    } catch (err) {
        dispatch(getCyclesFailure(err.toString()))
    }
}

export const fetchCycle = (id: number): AppThunk => async dispatch => {
    try {
        dispatch(getCycleStart())
        const cycle = await getCycle(id)
        dispatch(getCycleSuccess(cycle))
    } catch (err) {
        dispatch(getCycleFailure(err.toString()))
    }
}