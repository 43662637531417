import React from 'react'

import { Cycle } from '../../api/commonappAPI'
import { CycleListItem } from './CycleListItem'

import styles from './CyclesList.module.css'

interface Props {
    cycles: Cycle[]
}

export const CyclesList = ({ cycles }: Props) => {
    const renderedCycles = cycles.map(cycle => (
        <li key={cycle.id}>
            <CycleListItem {...cycle} />
        </li>
    ))

    return <ul className={styles.cyclesList}>{renderedCycles}</ul>
}
