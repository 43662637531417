
import React from 'react';

import { Link } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { LoginForm, SignOut } from "../login/components"

const { Header } = Layout;

const NavBar = () => {
  return (
    <Header>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{float: 'right'}}>
        <SignOut />
        {/* <Menu.Item key="1"><Link to="/homepage">Homepage</Link></Menu.Item> */}
        {/* <Menu.Item key="2"><Link to="/dashboard">Dashboard</Link></Menu.Item> */}
        {/* <GoogleLogin /> */}
        {/* <Menu.Item key="3"><Link to="/register">Register</Link></Menu.Item> */}
        {/* <Menu.Item key="4"><Link to="/login">Login</Link></Menu.Item> */}
        {/* <Menu.Item key="5"><Link to="/login"><LoginButton /></Link></Menu.Item> */}
      </Menu>
    </Header>
  );
}

export default NavBar;
