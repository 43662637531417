import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import * as serviceWorker from './serviceWorker';

import store, {rrfProps} from './app/store'

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {AuthIsLoaded} from "./features/auth/authIsLoaded";

const render = () => {
    const App = require('./app/App').default

    ReactDOM.render(
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <AuthIsLoaded>
                    <App />
                </AuthIsLoaded>
            </ReactReduxFirebaseProvider>
        </Provider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./app/App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
