import React, {useState} from "react";
import { handleSignInWithGoogle } from "../../features/auth/authSlice";
import { Redirect } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'

import {Form, Input, Button} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import styles from './Login.module.css'

const NormalLoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const handleLogin = async () => {
    try {
      await auth.signInWithEmailAndPassword(email, password)
      setRedirectToHome(true);
    } catch (err) {
      const {code, message} = err;
      console.log(`code: ${code}, message: ${message}`)
      setErrorMessage(message)
    } finally {
    }
  }
  const onChangeHandler = (event) => {
    event.preventDefault()
    const {name, value} = event.currentTarget;
    if (name === 'username') {
      setEmail(value);
    }
    else if (name === 'password') {
      setPassword(value);
    }
  }
  const auth = useFirebase().auth()
  if (redirectToHome) {
    return (
        <Redirect to="/" />
    );
  }
  return (
    <div className={styles.outerLoginContainer}>
      <div className={styles.middleLoginContainer}>
        <div className={styles.innerLoginContainer}>
          <Form
              name="normal_login"
              className={styles.normalLoginForm}
              onFinish={() => handleLogin()}
          >
            <Form.Item>
              <h2>Sign in to Ramen App</h2>
            </Form.Item>
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Username required'}]}
            >
              <Input
                  name="username"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email Address"
                  onChange = {(event) => onChangeHandler(event)}
              />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Password required' }]}
            >
              <Input
                  name="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  onChange = {(event) => onChangeHandler(event)}
              />
            </Form.Item>
            {errorMessage}
            <Form.Item>
              <a className="login-form-forgot" href="/forgot">
                I forgot my password
              </a>
            </Form.Item>
            <Form.Item>
              <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
              >
                Log in to Ramen App
              </Button>
              <br/>
              <hr className={styles.hrText} data-content="Or"/>
              <Button type="dashed" htmlType="button" className="login-with-google-button" onClick={handleSignInWithGoogle}>
                Sign in with Google
              </Button>
            </Form.Item>
            <Form.Item>
              <a href="/signup">
                Sign up for Ramen App
              </a>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NormalLoginForm;