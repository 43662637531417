import React from "react";

import HomeBanner from "./components";
import styles from "../app/App.module.css";
import {Layout} from "antd";
const {Footer} = Layout;


const HomePage: React.FC = () => {
  return (
    <div>
      <HomeBanner />
      <Footer className={styles.footer}>Ramen App ©2020</Footer>
    </div>
  );
}

export default HomePage;
