import React, {useState} from "react";
import { useFirebase } from 'react-redux-firebase'

import {Form, Input, Button} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import styles from './Login.module.css'

const NormalLoginForm = () => {
    const [email, setEmail] = useState('')
    const [resultMessage, setResultMessage] = useState('')
    const submitHandler = async event => {
        auth.sendPasswordResetEmail(email).then(function() {
            // Email sent
            setResultMessage("Recovery email sent.")
        }).catch(function(err) {
            // An error occurred
            const {code, message} = err
            console.log(`code: ${code}, message: ${message}`)
            setResultMessage(message)
        })
    }
    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'username') {
            setEmail(value);
        }
    }
    const auth = useFirebase().auth()

    return (
        <div className={styles.outerLoginContainer}>
            <div className={styles.middleLoginContainer}>
                <div className={styles.innerLoginContainer}>
                    <Form
                        name="normal_login"
                        className={styles.normalLoginForm}
                    >
                        <Form.Item>
                            <h2>Forgot password</h2>
                        </Form.Item>
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Email required'}]}
                        >
                            <Input
                                name="username"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email Address"
                                onChange = {(event) => onChangeHandler(event)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                onClick = {(event) => {submitHandler(event)}}
                            >
                                Send recovery email
                            </Button>
                            <br/>
                        </Form.Item>
                        <Form.Item>
                            {resultMessage}
                        </Form.Item>
                        <a className="login-f-forgot" href="/signin">
                            Go back to sign-in
                        </a>
                        <Form.Item>
                            <a href="/signup">
                                Sign up for Ramen App
                            </a>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default NormalLoginForm;
