import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {ApplicationsList} from "./ApplicationsList"

import {fetchApplications} from './ApplicationsSlice'
import {RootState} from "../../app/rootReducer";

export const ApplicationsListPage = () => {
    const dispatch = useDispatch()
    const {
        isLoading,
        error: applicationsError,
        applications
    } = useSelector((state: RootState) => state.applications)

    useEffect(() => {
        dispatch(fetchApplications())
    }, [dispatch])

    if (applicationsError) {
        return (
            <>
                <h1>Something went wrong...</h1>
                <>{applicationsError.toString()}</>
            </>
        )
    }

    let renderedList = isLoading ? (
        <h3>Loading...</h3>
    ) : (
        <ApplicationsList applications={applications}/>
    )

    return (
        <>
            {renderedList}
        </>
    )
}
