import React, { useState } from "react";
import './App.css';

import {Layout, Menu, Breadcrumb, Button} from 'antd';
import { AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import {CyclesListPage} from "../features/cyclesList/CyclesListPage";
import {ApplicationsListPage} from "../features/applicationsList/ApplicationsListPage";
import NavBar from '../components/NavBar';
import {pingNakama} from "../api/nakamaAPI";


const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const UserProfileHeader: React.FC = () => {
  return (
    <>
    </>
  );
}
const UserProfileBasic: React.FC = () => {
  return (
    <>
      <UserProfileHeader />
    </>
  );
}

const UserProfileAdvanced: React.FC = () => {
  return (
    <>
      <UserProfileHeader />
    </>
  );
}

const Dashboard: React.FC = () => {
  const [menuKey, setMenuKey] = useState('1');
  const [siderCollapsed, setSiderCollapsed] = useState(false)

  const renderSwitch = () => {
    switch(menuKey) {
      case '1':
        return <CyclesListPage />
      case '2':
        return <ApplicationsListPage />
      default:
        return (
          <>
            Test
            <Button type="primary" onClick={pingNakama}>Hit production Nakama server</Button>
          </>
        );
    }
  }

  const onCollapse = (collapsed: boolean) => {
    console.log(collapsed);
    setSiderCollapsed(collapsed)
  };

  return (
    <div>
      <NavBar/>
      <Layout>
        <Sider className="site-layout-background" collapsible collapsed={siderCollapsed} onCollapse={onCollapse} collapsedWidth={0}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            onClick={({ item, key, keyPath, domEvent }) => {
              setMenuKey(String(key));
            }}
          >
            <SubMenu key="sub1" icon={<AppstoreOutlined />} title="Apps">
              <Menu.Item key="1">Your Cycles</Menu.Item>
              <Menu.Item key="2">Your Applications</Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" icon={<SettingOutlined />} title="Settings">
              <Menu.Item key="5">Your Profile</Menu.Item>
              <Menu.Item key="6">Billing</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout >
          <Breadcrumb />
          <Content>
            { renderSwitch() }
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Dashboard;
