import React from 'react'
import { useSelector } from 'react-redux'
import { selectAuth } from './authSlice'
import { isLoaded } from 'react-redux-firebase'
import {Spin} from "antd";

export const AuthIsLoaded = ({ children }: any) => {
    const auth = useSelector(selectAuth)

    if (!isLoaded(auth))
        return (
            <Spin />
        )

    return children
}
