import "react-godot/dist/styles.css"

import React from "react"
import ReactGodot from 'react-godot'
import {Button, Form} from "antd";
import {pingNakama} from "../api/nakamaAPI";

class Game2 extends React.Component {
    render() {
        const width = 1280;
        const height = 720;
        return (
            <>
                {/* <Button type="primary" onClick={pingNakama}>Hit production Nakama server</Button> */}
                <ReactGodot execname='/nakamademo323/AmongUsRemake' width={width} height={height} />
            </>
    )
    }
}

export default Game2;
