import React from "react";

import RegistrationForm from "./components";

const RegistrationApp: React.FC = () => {
  return (
    <div>
      <RegistrationForm />
    </div>
  );
}

export default RegistrationApp;
