import { combineReducers } from '@reduxjs/toolkit'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import authReducer from '../features/auth/authSlice'
import cyclesReducer from '../features/cyclesList/CyclesSlice'
import applicationsReducer from '../features/applicationsList/ApplicationsSlice'

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    cycles: cyclesReducer,
    applications: applicationsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer