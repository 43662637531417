import axios from 'axios'
import { Client } from '@heroiclabs/nakama-js'

const BASE_URL = '35.188.40.18'

export interface Community {
    id: number
    name: string
}

export interface Cycle {
    id: number
    name: string
    community: number
}

export interface Application {
    id: number
    cycle: number
    email: string
    application_data: string
}

export interface CommunitiesResult {
    communities: Community[]
}

export interface CyclesResult {
    cycles: Cycle[]
}

export interface ApplicationsResult {
    applications: Application[]
}

export async function getCommunities(): Promise<CommunitiesResult> {
    const url = `http://${BASE_URL}/api2/closeknit/community/`

    try {
        const communitiesResponse = await axios.get<Community[]>(url)
        return {communities: communitiesResponse.data}
    } catch (err) {
        throw err
    }
}

export async function getCycles(): Promise<CyclesResult> {
    const url = `http://${BASE_URL}/api2/commonapp/cycles/`

    try {
        const cyclesResponse = await axios.get<Cycle[]>(url)
        return {cycles: cyclesResponse.data}
    } catch (err) {
        throw err
    }
}

export async function getApplications(): Promise<ApplicationsResult> {
    const url = `http://${BASE_URL}/api2/commonapp/applications/`

    try {
        const applicationsResponse = await axios.get<Application[]>(url)
        return {applications: applicationsResponse.data}
    } catch (err) {
        throw err
    }
}

export async function getApplication(id: number): Promise<Application> {
    const url = `http://${BASE_URL}/api2/commonapp/applications/{id}`

    const { data } = await axios.get<Application>(url)
    return data
}

export async function getCycle(id: number): Promise<Cycle> {
    const url = `http://${BASE_URL}/api2/commonapp/cycles/{id}`

    const { data } = await axios.get<Cycle>(url)
    return data
}

export async function pingNakama() {
    var client = new Client("defaultkey", BASE_URL, "7350");
    const email = "hello@example.com";
    const password = "somesupersecretpassword";
    const session = await client.authenticateEmail({
        email: email,
        password: password
    });
    // Store session for quick reconnects.
    localStorage.nakamaAuthToken = session.token;
    console.info("Authenticated successfully. User id:", session.user_id);
}

